import React from "react"

import { BodyWrapper, CardWrapper, MainWrapper, Subtitle, Title, Link } from "./styles"

export default function Success() {
  return <BodyWrapper>
    <MainWrapper>
      <CardWrapper>
        <Title>Obrigado!</Title>
        <Subtitle>O seu formulário foi recebido.</Subtitle>
        <Subtitle>
          <Link href="/">← Voltar para o site</Link>
        </Subtitle>
      </CardWrapper>
    </MainWrapper>
  </BodyWrapper>
}
