import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../styles/colors"


export const BodyWrapper = styled.main`
  background: rgb(14, 30, 37);
  color: white;
  overflow: hidden;
  margin: 0;
  padding: 0;
`

export const MainWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100vw;
`

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 364px;
  padding: 24px;
  background: white;
  color: rgb(14, 30, 37);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, .16);

  > a {
    margin: 0;
    text-decoration: none;
    font-weight: 600;
    line-height: 24px;
    color: #007067;
  }
  > a svg {
    position: relative;
    top: 2px;
  }
  > a:hover, a:focus {
    text-decoration: underline;
    text-decoration-color: #f4bb00;
  }
  > a:hover svg path{
    fill: #007067;
  }
  > p:last-of-type {
    margin: 0;
  }
`

export const Title = styled.div`
  margin: 0;
  font-size: 22px;
  line-height: 24px;
`
export const Subtitle = styled.p`
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
`

export const Link = styled.a`
  margin: 0;
  text-decoration: none;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.main};
  :focus {
    text-decoration: underline;
    text-decoration-color: #f4bb00;
  }
  styled.svg {
    position: relative;
    top: 2px;
  }
`
