import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Success from "../components/Success"
import IndexPage from "."

const SuccessPage = (props) => (
  props.location.state?.allowed ? <Layout>
    <SEO title="Sucesso" />
    <Success></Success>
  </Layout> : <IndexPage></IndexPage>
)

export default SuccessPage
